import {
  ILocationSearchRequest,
  IEncodedSearchQueryParams,
} from '../location.types';

import {
  encodeQuery,
  encodeDocumentType,
  encodePage,
  encodeSort,
  encodeProductCollections,
  encodeProductPrice,
  encodeForumContentType,
  encodeForumCategory,
} from './serializers';

export function encodeSearchRequestToQueryParams(
  request: ILocationSearchRequest,
): IEncodedSearchQueryParams {
  const q = encodeQuery(request.query);
  const type = encodeDocumentType(request.documentType);
  const page = encodePage(request.page);
  const sort = encodeSort(request.sort);
  const collections = encodeProductCollections(request.collections);
  const price = encodeProductPrice({
    minPrice: request.minPrice,
    maxPrice: request.maxPrice,
  });
  const contentType = encodeForumContentType(request.contentType);
  const categoryTitle = encodeForumCategory(request.categoryTitle);

  return {
    ...(q !== undefined && { q }),
    ...(type !== undefined && { type }),
    ...(page !== undefined && { page }),
    ...(collections !== undefined && { collections }),
    ...(price !== undefined && { price }),
    ...(sort !== undefined && { sort }),
    ...(contentType !== undefined && { contentType }),
    ...(categoryTitle !== undefined && { categoryTitle }),
  };
}
