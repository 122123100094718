import decodeUriComponent from 'decode-uri-component';

const COLLECTIONS_DELIMITER = ',';

export function encodeProductCollections(
  collections?: string[],
): string | undefined {
  if (!collections || !collections.length) {
    return;
  }

  return [...collections].sort().join(COLLECTIONS_DELIMITER);
}

export function decodeProductCollections(
  urlCollections?: string,
): string[] | undefined {
  if (!urlCollections) {
    return;
  }

  return decodeUriComponent(urlCollections).split(COLLECTIONS_DELIMITER);
}
