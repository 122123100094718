interface PriceRange {
  minPrice?: number;
  maxPrice?: number;
}

const PRICE_DELIMITER = '-';

export function encodeProductPrice(price: PriceRange): string | undefined {
  if (!price) {
    return;
  }

  const { minPrice, maxPrice } = price;
  if (minPrice === undefined && maxPrice === undefined) {
    return;
  }

  return `${minPrice ?? ''}${PRICE_DELIMITER}${maxPrice ?? ''}`;
}

export function decodeProductPrice(urlPrice?: string): PriceRange {
  if (typeof urlPrice !== 'string') {
    return {};
  }

  const [min, max] = urlPrice.split(PRICE_DELIMITER);
  const minPrice = parseInt(min, 10);
  const maxPrice = parseInt(max, 10);

  return {
    ...(!isNaN(minPrice) && { minPrice }),
    ...(!isNaN(maxPrice) && { maxPrice }),
  };
}
