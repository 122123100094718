import { createContext } from 'react';
import { IWidgetRootProps } from '../components/WidgetRoot';

export type SearchEnvironment = Pick<
  IWidgetRootProps,
  'viewMode' | 'isDemoContent' | 'locale' | 'currency'
>;

export const SearchEnvironmentContext = createContext<SearchEnvironment>(
  {} as SearchEnvironment,
);
