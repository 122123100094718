export const encodeForumCategory = (
  categoryTitle?: string,
): string | undefined => {
  if (!categoryTitle) {
    return;
  }

  return categoryTitle;
};

export const decodeForumCategory = (
  categoryTitle?: string,
): string | undefined => {
  if (!categoryTitle) {
    return;
  }

  return categoryTitle;
};
