import { ForumContentType } from '@wix/client-search-sdk';

const POST_CONTENT_TYPE = 'post';
const COMMENT_CONTENT_TYPE = 'comment';

const contentTypeEncodingMap: Record<ForumContentType, string> = {
  [ForumContentType.Post]: POST_CONTENT_TYPE,
  [ForumContentType.Comment]: COMMENT_CONTENT_TYPE,
};

const contentTypeDecodingMap: Record<string, ForumContentType> = {
  [POST_CONTENT_TYPE]: ForumContentType.Post,
  [COMMENT_CONTENT_TYPE]: ForumContentType.Comment,
};

export const encodeForumContentType = (
  contentType?: ForumContentType,
): string | undefined => {
  return contentType ? contentTypeEncodingMap[contentType] : undefined;
};

export const decodeForumContentType = (
  encodedContentType?: string,
): ForumContentType | undefined => {
  return encodedContentType
    ? contentTypeDecodingMap[encodedContentType]
    : undefined;
};
