import {
  ApiTypes,
  ISearchRequest,
  ISearchResponse,
  ForumContentType,
} from '@wix/client-search-sdk';

import { SearchRequestStatus } from '../../types/types';
import { SearchParams } from '../search';

export type IForumFacetsFilter = {
  contentType?: ForumContentType;
  categoryTitle?: string;
};
export interface IForumFacetsCollection {
  value: string;
  count: number;
}

export type IForumFacetsState = {
  enabled: boolean;
  filter: IForumFacetsFilter;
  categories: IForumFacetsCollection[];
  contentTypes: IForumFacetsCollection[];
};

const FACET_CONTENT_TYPE = 'contentType';
const FACET_CATEGORY_TITLE = 'categoryTitle';

export const convertForumFacetsFilterToRequestParams = ({
  contentType,
  categoryTitle,
}: IForumFacetsFilter = {}) => {
  const combinedFilter = [];
  const hasContentType = contentType !== undefined;
  const hasCategoryTitle = categoryTitle !== undefined;

  const filterContent = hasContentType
    ? { contentType: { $eq: contentType } }
    : undefined;

  const filterCategory = hasCategoryTitle
    ? { categoryTitle: { $eq: categoryTitle } }
    : undefined;

  if (filterContent) {
    combinedFilter.push(filterContent);
  }

  if (filterCategory) {
    combinedFilter.push(filterCategory);
  }

  const filter =
    combinedFilter.length > 0 ? { $and: combinedFilter } : undefined;

  return {
    facets: {
      clauses: [
        { term: { name: FACET_CONTENT_TYPE, limit: 10 } },
        { term: { name: FACET_CATEGORY_TITLE, limit: 10 } },
      ],
    },
    filter,
  };
};

export const convertForumFacetsRequestParamsToFilter = ({
  filter,
}: Pick<ISearchRequest, 'filter'>): IForumFacetsFilter => {
  if (!filter || !filter.$and) {
    return {};
  }

  const contentTypeFilter = filter.$and.find(({ contentType }) => !!contentType)
    ?.contentType as ApiTypes.ISearchRequestFilterItem;

  const forumCategoryFilter = filter.$and.find(
    ({ categoryTitle }) => !!categoryTitle,
  )?.categoryTitle as ApiTypes.ISearchRequestFilterItem;

  const contentType = contentTypeFilter?.$eq as ForumContentType;
  const categoryTitle = forumCategoryFilter?.$eq;

  return {
    contentType,
    categoryTitle,
  };
};

export const extractForumFacetsFromSearchResponse = ({
  facets,
}: Pick<ISearchResponse, 'facets'>): Pick<
  IForumFacetsState,
  'contentTypes' | 'categories'
> => {
  const contentTypes = (
    facets.find(({ terms }) => !!terms && terms.facet === FACET_CONTENT_TYPE)
      ?.terms?.facets ?? []
  ).map(({ facetValue, count }) => ({ value: facetValue, count }));

  const categories = (
    facets.find(({ terms }) => !!terms && terms.facet === FACET_CATEGORY_TITLE)
      ?.terms?.facets ?? []
  ).map(({ facetValue, count }) => ({ value: facetValue, count }));

  return {
    contentTypes,
    categories,
  };
};

export const mergeForumFacets = (
  contentTypeResponse: ISearchResponse,
  categoriesResponse: ISearchResponse,
): ApiTypes.ISearchResponseFacet[] => {
  const facets: ApiTypes.ISearchResponseFacet[] = [];
  const contentTypeFacets = contentTypeResponse?.facets;
  const categoriesFacets = categoriesResponse?.facets;

  const contentTypeFacet = contentTypeFacets.find(
    ({ terms }) => terms?.facet === FACET_CONTENT_TYPE,
  );

  if (contentTypeFacet) {
    facets.push(contentTypeFacet);
  }

  const categoriesFacet = categoriesFacets.find(
    ({ terms }) => terms?.facet === FACET_CATEGORY_TITLE,
  );

  if (categoriesFacet) {
    facets.push(categoriesFacet);
  }

  return facets;
};

export const shouldRequestContentTypeFacet = ({
  previousSearchRequestStatus,
}: Pick<SearchParams, 'previousSearchRequestStatus'>) => {
  if (previousSearchRequestStatus === SearchRequestStatus.Initial) {
    return true;
  }

  return false;
};

export const shouldRequestCategoriesFacet = ({
  previousSearchRequestStatus,
}: Pick<SearchParams, 'previousSearchRequestStatus'>) => {
  if (previousSearchRequestStatus === SearchRequestStatus.Initial) {
    return true;
  }

  return false;
};
